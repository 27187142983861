import React from 'react';
import { ReactComponent as CloseIcon } from '../../images/close.svg';

type Props = {
  title: Object,
  show: Boolean,
  children: Object,
  hideModal: Function,
};
const Modal = ({ title, show, children, hideModal }: Props) => (
  <div className={`modal-overlay ${show && 'show'}`}>
    <div className="modal-container">
      <div className="modal-header">
        {title}
        <button className="button modal-close-button" type="button">
          <CloseIcon onClick={hideModal} />
        </button>
      </div>
      <div className="modal-content">{children}</div>
    </div>
  </div>
);

export default Modal;
