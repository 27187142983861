import React from 'react';
import times from 'lodash/times';
import replace from 'lodash/replace';
import { evaluate } from 'mathjs';
import LZString from 'lz-string';

import { validate } from './validator';
import {
  VAR_REGEX,
  CURRENCY_SYMBOLS,
  PERCENT_REGEX,
  RANGE_REGEX,
  LETTER_REGEX,
  INDIVIDUAL_CELLS_REGEX,
  POSTLIGHT_URL
} from '../constants';
import {
  pickColor,
  readytToPickColor,
  textShadowColor,
  activeCellColor,
  editColor,
} from '../constants/colors';

export const inputStyle = {
  color: 'transparent',
  textShadow: `0 0 0 ${textShadowColor}`,
};

export const editStyle = {
  background: editColor,
  cursor: 'text',
};

export const rightStyle = {
  color: 'transparent',
  textShadow: `0 0 0 ${textShadowColor}`,
  textAlign: 'right',
};

export const activeStyle = {
  background: activeCellColor,
};

export const pickStyle = {
  outline: `1px dashed ${pickColor}`,
  position: `relative`,
  zIndex: `1`,
};

export const readyToPickStyle = {
  outline: `1px dashed ${readytToPickColor}`,
  position: `relative`,
  zIndex: `1`,
};

export const initialPickMode = {
  picking: false,
  shiftPressed: false,
  controlPressed: false,
  cell: [-1, -1],
  pickCell: [-1, -1],
  rangeCells: [-1, -1, -1, -1],
  text: '',
  position: -1,
};

export const createEmptyGrid = (numbOfRows, numbOfCol) => {
  return times(numbOfRows, () =>
    times(numbOfCol, () => ({
      text: '',
      initialText: '',
      value: '',
      formula: null,
      ref: React.createRef(),
      editing: false,
      valid: true,
    })),
  );
};

export const createEmptyComments = (numbOfRows) => {
  return times(numbOfRows, () => '');
};

export const isRangeCells = (pickCells) => {
  return pickCells[0] !== pickCells[2] || pickCells[1] !== pickCells[3];
};

export const getRangeCellsText = (arr) => {
  const lowRow = arr[0] > arr[2] ? arr[2] : arr[0];
  const highRow = arr[0] > arr[2] ? arr[0] : arr[2];
  const lowCol = arr[1] > arr[3] ? arr[3] : arr[1];
  const highCol = arr[1] > arr[3] ? arr[1] : arr[3];
  const firstletter = lowCol === 0 ? 'A' : 'B';
  const firstCell = `${firstletter}${lowRow + 1}`;
  const secondletter = highCol === 0 ? 'A' : 'B';
  const secondCell = `${secondletter}${highRow + 1}`;
  return {
    firstCell,
    secondCell,
  };
};

export const isInInterval = (rowIndex, colIndex, arr) => {
  const lowRow = arr[0] > arr[2] ? arr[2] : arr[0];
  const highRow = arr[0] > arr[2] ? arr[0] : arr[2];
  const lowCol = arr[1] > arr[3] ? arr[3] : arr[1];
  const highCol = arr[1] > arr[3] ? arr[1] : arr[3];

  if (
    lowRow <= rowIndex &&
    lowCol <= colIndex &&
    highRow >= rowIndex &&
    highCol >= colIndex
  ) {
    return true;
  }
  return false;
};

export const isOpenParentheses = (text) => {
  if (!text) {
    return false;
  }
  const openParentheses = (text.match(/\(/g) || []).length;
  const closedParentheses = (text.match(/\)/g) || []).length;
  return openParentheses > closedParentheses;
};

export const isArithmeticOperator = (operator) => {
  if (
    operator === '=' ||
    operator === '+' ||
    operator === '-' ||
    operator === '*' ||
    operator === '(' ||
    operator === ':' ||
    operator === ',' ||
    operator === '/'
  ) {
    return true;
  }
  return false;
};

export const isValid = (value) => {
  try {
    const processedValue = value.replace(/ {2}/g, '');
    return validate(processedValue);
  } catch (e) {
    return false;
  }
};

export const formatValue = (value) => {
  if (!value || value.length === 0) {
    return value;
  }

  if (!isValid(value)) {
    return value;
  }

  let prefix = '';
  let suffix = '';
  let maximumFractionDigits = 5;
  let minimumFractionDigits = 0;
  const res = value.search(CURRENCY_SYMBOLS);
  if (res > -1) {
    prefix = value.charAt(res);
    maximumFractionDigits = 2;
    minimumFractionDigits = 2;
  }
  if (value.includes('%')) {
    suffix = '%';
  }
  const processedValue = value.replace(/[^\d.-]/g, '');
  try {
    const result = evaluate(processedValue).toLocaleString(navigator.language, {
      minimumFractionDigits,
      maximumFractionDigits,
    });
    return `${prefix}${result}${suffix}`;
  } catch (e) {
    return value;
  }
};

export const getCellsInRange = (cells) => {
  const cell1 = cells[0];
  const cell1col = cell1.charAt(0).toUpperCase() === 'A' ? 0 : 1;
  const cell1row = parseInt(cell1.substring(1), 10) - 1;
  const cell2 = cells[1];
  const cell2col = cell2.charAt(0).toUpperCase() === 'A' ? 0 : 1;
  const cell2row = parseInt(cell2.substring(1), 10) - 1;

  const res = [];
  for (let i = 0; i < 10; i += 1) {
    if (isInInterval(i, 0, [cell1row, cell1col, cell2row, cell2col])) {
      res.push(`A${i + 1}`);
    }
    if (isInInterval(i, 1, [cell1row, cell1col, cell2row, cell2col])) {
      res.push(`B${i + 1}`);
    }
  }
  return res;
};

export const getMethodName = (expression, i) => {
  let index = i;
  let method = '';
  while (index >= 0) {
    index -= 1;
    if (expression.charAt(index).match(LETTER_REGEX)) {
      method = expression.charAt(index) + method;
    } else {
      index = -1;
    }
  }
  return method;
};

export const explodeCellsInExpression = (
  cells,
  method,
  toReplace,
  expression,
) => {
  switch (method.toLowerCase()) {
    case 'sum': {
      const string = cells.join('+');
      const replacement = `(${string})`;
      return replace(expression, toReplace, replacement);
    }
    case 'avg':
    case 'average': {
      const string = cells.join('+');
      const replacement = `((${string})/${cells.length})`;
      return replace(expression, toReplace, replacement);
    }

    default:
      return '';
  }
};

export const evaluateExpression = (formula, grid, currentRow, currentCol) => {
  let expression = formula.substring(1).trim();
  if (expression.length === 0) {
    return 0;
  }
  const ranges = formula.match(RANGE_REGEX);
  const individualCells = formula.match(INDIVIDUAL_CELLS_REGEX);
  let variables = formula.match(VAR_REGEX);

  if (!variables) {
    variables = [];
  }

  if (individualCells) {
    individualCells.forEach((cells) => {
      const index = expression.indexOf(cells);
      const method = getMethodName(expression, index);
      const fcells = cells.substring(1, cells.length - 1).split(',');
      fcells.forEach((cell) => {
        variables.push(cell);
      });
      expression = explodeCellsInExpression(
        fcells,
        method,
        method + cells,
        expression,
      );
    });
  }

  if (ranges) {
    ranges.forEach((range) => {
      const index = expression.indexOf(range);
      const method = getMethodName(expression, index);
      const cells = range.substring(1, range.length - 1).split(':');
      const fcells = getCellsInRange(cells);
      fcells.forEach((cell) => {
        variables.push(cell);
      });

      expression = explodeCellsInExpression(
        fcells,
        method,
        method + range,
        expression,
      );
    });
  }
  if (expression === '') {
    return 'Error';
  }

  let currency = '';
  let maximumFractionDigits = 5;
  let minimumFractionDigits = 0;

  variables.forEach((variable) => {
    let col = 1;
    if (variable.includes('a') || variable.includes('A')) {
      col = 0;
    }
    if (variable.length < 2) {
      return;
    }
    const index = parseInt(variable.substring(1), 10) - 1;
    if (index < 0 || index > 9) {
      return;
    }
    if (col === currentCol && index === currentRow) {
      return;
    }
    const { value } = grid[index][col];

    if (!isValid(value)) {
      return;
    }

    const res = value.search(CURRENCY_SYMBOLS);
    if (res > -1) {
      currency = value.charAt(res);
      maximumFractionDigits = 2;
      minimumFractionDigits = 2;
    }
    let processedValue = value.replace(/[^\d.-]/g, '');
    if (value.includes('%')) {
      processedValue /= 100;
    }

    expression = replace(
      expression,
      variable,
      value === '' ? 0 : processedValue,
    );
  });

  const percents = expression.match(PERCENT_REGEX);
  if (percents) {
    percents.forEach((percent) => {
      const value = percent.replace('%', '') / 100;
      expression = replace(expression, percent, value);
    });
  }

  try {
    const result = evaluate(expression).toLocaleString(navigator.language, {
      minimumFractionDigits,
      maximumFractionDigits,
    });

    if (result.startsWith('function') || result === '∞' || result === '-NaN') {
      return 'Error';
    }

    return `${currency}${result}`;
  } catch (e) {
    return 'Error';
  }
};

export const createFilledGrid = (numbOfRows, numbOfCol, data) => {
  let index = -1;
  const newGrid = times(numbOfRows, () =>
    times(numbOfCol, () => {
      index += 1;
      const text = data[index] ? data[index] : '';
      let value = '';
      let valid = true;
      value = formatValue(text);
      valid = isValid(text);
      if (text.startsWith('=')) {
        valid = true;
      }
      return {
        text,
        initialText: '',
        value,
        valid,
        formula: null,
        ref: React.createRef(),
        editing: false,
      };
    }),
  );

  newGrid.forEach((row, rIndex) => {
    row.forEach((col, cIndex) => {
      if (col.text.startsWith('=')) {
        newGrid[rIndex][cIndex].value = evaluateExpression(
          col.text,
          newGrid,
          rIndex,
          cIndex,
        );
      }
    });
  });

  return newGrid;
};

export const reevaluateExpressions = (grid) => {
  grid.forEach((row, rIndex) => {
    row.forEach((col, cIndex) => {
      if (col.text.startsWith('=')) {
        // eslint-disable-next-line no-param-reassign
        grid[rIndex][cIndex].value = evaluateExpression(
          col.text,
          grid,
          rIndex,
          cIndex,
        );
      }
    });
  });
};

export const generateHash = (grid, comments, sheetTitle) => {
  let shareArr = [];
  const commentsArr = comments;
  const hasComment =
    comments && comments.filter((comment) => comment).length > 0;

  let lastRow = 0;
  grid.forEach((row, index) => {
    row.forEach((element) => {
      if (element.text) {
        lastRow = 2 + 2 * index;
      }
      shareArr.push(element.text);
    });
  });
  shareArr = shareArr.slice(0, lastRow);
  if (shareArr.length === 0 && sheetTitle === '' && !hasComment) {
    return '';
  }

  const data = {
    shareArr,
    sheetTitle,
    commentsArr,
  };

  const shareString = JSON.stringify(data);
  const hash = LZString.compressToEncodedURIComponent(shareString);
  return hash;
};

export const shouldPrependZero = (text) => {
  if (
    text.startsWith('.') &&
    text.split('.').length === 2 &&
    !text.includes(',') &&
    isValid(text.substr(1))
  ) {
    return true;
  }
  return false;
};

export const generateCurrentSheetURL = (isEmbed) =>
  `${window.location.origin}/${isEmbed ? 'embed' : ''}${
    window.location.search
  }${window.location.hash}`;

export const sendGtagClickEventPLDotCom = () => {
  const {gtag} = window;
  if (gtag) {
    gtag('event', 'click', {
      event_category: 'outbound',
      event_label: POSTLIGHT_URL,
      transport_type: 'beacon',
      event_callback() {
        document.location = POSTLIGHT_URL
      }
    });
  }
  return false;
};
