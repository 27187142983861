import { CURRENCY_SYMBOLS_TEXT } from '../constants';

export const isValidNumber = (value, suffixAllowedArg) => {
  const string = String(value);
  let suffixAllowed = suffixAllowedArg;
  let firstDigit = -1;
  let lastDigit = -1;
  for (let i = 0; i < string.length; i += 1) {
    const val = string.charAt(i);
    if ('0123456789'.includes(val)) {
      if (firstDigit < 0) {
        firstDigit = i;
      }
      lastDigit = i;
    }
    if (i === 0) {
      if (!'0123456789+-'.includes(val)) {
        if (suffixAllowed) {
          suffixAllowed = false;
        } else {
          return false;
        }
      }
    } else if (!'0123456789,'.includes(val)) {
      if (suffixAllowed) {
        suffixAllowed = false;
      } else {
        return false;
      }
    }
  }

  const number = string.substring(firstDigit, lastDigit + 1).replace(/,/g, '');
  if (!/^\d+$/.test(number)) {
    return false;
  }

  const splitted = string.split(',');
  if (splitted.length > 1) {
    for (let i = 0; i < splitted.length; i += 1) {
      if (parseInt(splitted[i], 10) > 999) {
        return false;
      }
      if (i > 0) {
        if (parseInt(splitted[i], 10) < 0) {
          return false;
        }
      } else if (parseInt(splitted[i], 10) === 0) {
        return false;
      }
    }
  }

  return true;
};

export const isValidDecimalNumber = value => {
  const string = String(value);
  const firstChar = string.charAt(0);
  if (!'0123456789'.includes(firstChar)) {
    return false;
  }

  let suffixAllowed = true;
  for (let i = 0; i < string.length; i += 1) {
    const val = string.charAt(i);
    if (!'0123456789'.includes(val)) {
      suffixAllowed = false;
    } else if (!suffixAllowed) {
      return false;
    }
  }
  return true;
};

export const validate = value => {
  if (!value || value.length === 0) {
    return true;
  }

  let string = String(value);
  const firstChar = string.charAt(0);
  const lastChar = string.charAt(string.length - 1);
  if (
    !`${CURRENCY_SYMBOLS_TEXT}0123456789.%+-`.includes(firstChar) ||
    !`${CURRENCY_SYMBOLS_TEXT}0123456789.%+-`.includes(lastChar)
  ) {
    return false;
  }
  if (
    !'0123456789.+-'.includes(firstChar) &&
    !'0123456789'.includes(lastChar)
  ) {
    return false;
  }
  if (`${CURRENCY_SYMBOLS_TEXT}%`.includes(firstChar)) {
    string = string.substring(1);
  }

  const splitted = string.split('.');
  if (splitted.length > 2) {
    return false;
  }
  if (splitted.length === 2) {
    return (
      isValidNumber(splitted[0], false) && isValidDecimalNumber(splitted[1])
    );
  }
  return isValidNumber(string, true);
};
