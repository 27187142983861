import React from 'react';
import ReactDOM from 'react-dom';
import { init } from '@sentry/browser';

import App from './App';

init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.NODE_ENV,
});

ReactDOM.render(
  <App pathArr={window.location.pathname.split('/').slice(1)} />,
  document.getElementById('root'),
);
