import React, { useRef, useEffect } from 'react';
import { ReactComponent as EmbedIcon } from '../../images/embed.svg';
import { generateCurrentSheetURL } from '../../helpers';
import Modal from '../Modal';

type Props = {
  show: Boolean,
  hideModal: Function,
};

const IFRAME_WIDTH = '100%';
const IFRAME_HEIGHT = '600px';

const EmbedModal = ({ show, hideModal }: Props) => {
  const src = generateCurrentSheetURL(true);
  const snippet = useRef(null);

  useEffect(() => {
    if (show && snippet) {
      const range = document.createRange();
      const sel = window.getSelection();
      range.selectNodeContents(snippet.current);
      sel.removeAllRanges();
      sel.addRange(range);
    }
  }, [show, snippet]);

  return (
    <Modal
      title={
        <span className="modal-title-container">
          <EmbedIcon className="modal-title-icon" />
          <span className="modal-title-text">Embed</span>
        </span>
      }
      show={show}
      hideModal={hideModal}
    >
      <span className="modal-subtitle">
        Embed an interactive copy of this Tinysheet into a website or blog post
        by using the iframe below.
      </span>
      <div className="modal-embed-snippet" ref={snippet}>
        {`<iframe
            title="${document.title}"
            src="${src}"
            height="${IFRAME_HEIGHT}"
            width="${IFRAME_WIDTH}"
            allowtransparency="true"
            frameborder="0"
          ></iframe>`}
      </div>
    </Modal>
  );
};

export default EmbedModal;
