import React, { useState, useEffect, useRef } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import LZString from 'lz-string';
import { isMobile } from 'react-device-detect';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDebouncedCallback } from 'use-debounce';
import { ReactComponent as ShareIcon } from '../../images/share.svg';
import { ReactComponent as CopyIcon } from '../../images/copy.svg';
import { ReactComponent as EmbedIcon } from '../../images/embed.svg';
import { ReactComponent as RefreshIcon } from '../../images/refresh.svg';
import { ReactComponent as SavingIcon } from '../../images/saving.svg';
import { ReactComponent as TwitterIcon } from '../../images/twitter.svg';
import { ReactComponent as Wordmark } from '../../images/wordmark-dark.svg';
import { ReactComponent as Logo } from '../../images/logo-dark.svg';
import Toolbox from '../Toolbox';
import Comment from '../Comment';
import EmbedModal from '../EmbedModal';
import {
  rowAndColIndicatorColor,
  emptyTopLeftCellColor,
  selectedCellColor,
} from '../../constants/colors';
import { POSTLIGHT_URL } from '../../constants';
import {
  createEmptyGrid,
  createEmptyComments,
  createFilledGrid,
  evaluateExpression,
  formatValue,
  inputStyle,
  rightStyle,
  pickStyle,
  readyToPickStyle,
  activeStyle,
  editStyle,
  generateHash,
  isValid,
  initialPickMode,
  reevaluateExpressions,
  isInInterval,
  isRangeCells,
  getRangeCellsText,
  isArithmeticOperator,
  isOpenParentheses,
  shouldPrependZero,
  generateCurrentSheetURL,
  sendGtagClickEventPLDotCom
} from '../../helpers';

const setDocumentTitle = debounce((title) => {
  document.title = title && title !== '' ? `${title} - Tinysheet` : 'Tinysheet';
}, 250);

function shareTitle(title) {
  return title && title !== ''
    ? `"${title}" - check out my Tinysheet, a mini-spreadsheet by @PostlightStudio`
    : 'Check out my Tinysheet, a mini-spreadsheet by @PostlightStudio';
}

type Props = {
  isEmbed: Boolean,
};

const Sheet = ({ isEmbed }: Props) => {
  const [grid, setGrid] = useState(createEmptyGrid(10, 2));
  const [showShareMenu, setShowShareMenu] = useState(false);
  const [comments, setComments] = useState(createEmptyComments(10, 2));
  const [displayedCommentIndex, setDisplayedCommentIndex] = useState(-1);
  const [activeCell, setActiveCell] = useState([-1, -1]);
  const [previousActiveCell, setPreviousActiveCell] = useState([-1, -1]);
  const [init, setInit] = useState(false);
  const [initialHash, setInitialHash] = useState('');
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [sheetTitle, setSheetTitle] = useState('');
  const [pickMode, setPickMode] = useState(initialPickMode);
  const [operatorsBarRowIndex, setOperatorsBarRowIndex] = useState(-1);
  const [equalButtonValue, setEqualButtonValue] = useState('=');
  const [isSaved, setIsSaved] = useState(false);
  const [showCopy, setShowCopy] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showSaveIndicator] = useDebouncedCallback(() => {
    setIsSaved(true);
    setTimeout(() => setIsSaved(false), 1000);
  }, 500);
  const [showEmbedModal, setShowEmbedModal] = useState(false);

  const resetButton = useRef(null);
  const [updateURL] = useDebouncedCallback((hash) => {
    window.history.replaceState(
      {},
      null,
      `?t=${encodeURIComponent(btoa(sheetTitle))}#${hash}`,
    );
  }, 500);

  useEffect(() => {
    if (window.location.hash.slice(1) && !init) {
      try {
        const str = LZString.decompressFromEncodedURIComponent(
          window.location.hash.slice(1),
        );
        const data = JSON.parse(str);
        const newGrid = createFilledGrid(10, 2, data.shareArr);
        setGrid(newGrid);
        setComments(data.commentsArr);
        setSheetTitle(data.sheetTitle);
        setInitialHash(window.location.hash);
        // eslint-disable-next-line no-empty
      } catch (e) {}
    } else if (window.history.replaceState) {
      const hash = generateHash(grid, comments, sheetTitle);
      if (hash.length > 0) {
        setShowCopy(true);
        if (window.location.hash.slice(1) !== hash) {
          updateURL(hash);
        }
      } else {
        window.history.replaceState({}, null, '/');
      }
      if (activeCell[0] === -1 && !isMobile && !isEmbed) {
        grid[0][0].ref.current.focus();
      }
    }
    setInit(true);
  }, [init, grid, comments, sheetTitle]); // eslint-disable-line

  useEffect(() => {
    setDocumentTitle(sheetTitle);
  });

  const getPickedCellsText = () => {
    if (isRangeCells(pickMode.rangeCells)) {
      const cells = getRangeCellsText(pickMode.rangeCells);
      if (isOpenParentheses(pickMode.text)) {
        return `${pickMode.text + cells.firstCell}:${cells.secondCell}`;
      }
      return pickMode.text;
    }
    if (pickMode.pickCell[0] > -1) {
      const letter = pickMode.pickCell[1] === 0 ? 'A' : 'B';
      const cell = `${letter}${pickMode.pickCell[0] + 1}`;
      return pickMode.text + cell;
    }
    return pickMode.text;
  };

  const closeParenthesesMobile = () => {
    let cind = -1;
    let rind = -1;
    grid.forEach((row, rIndex) => {
      row.forEach((col, cIndex) => {
        if (col.text.startsWith('=') && isOpenParentheses(col.text)) {
          cind = cIndex;
          rind = rIndex;
        }
      });
    });
    if (cind > -1) {
      grid[rind][cind].text = `${grid[rind][cind].text})`;
      // eslint-disable-next-line no-use-before-define
      onBlur(rind, cind);
    }
  };

  const checkFocus = () => {
    if (
      localStorage.getItem('lastInteraction') &&
      localStorage.getItem('lastInteraction') === 'blur'
    ) {
      if (isMobile) {
        setOperatorsBarRowIndex(-1);
        closeParenthesesMobile();
      } else {
        const newPickMode = cloneDeep(pickMode);
        newPickMode.controlPressed = false;
        newPickMode.shiftPressed = false;
        setPickMode(newPickMode);
      }
    }
  };

  const updateOperatorsBar = (overwriteVal = null) => {
    if (overwriteVal && equalButtonValue !== overwriteVal) {
      setEqualButtonValue(overwriteVal);
    }
  };

  const onBlur = (rowIndex, colIndex, force = false, fake = true, e = null) => {
    // Do not trigger if it's a toolbox action
    if (
      e &&
      e.relatedTarget &&
      e.relatedTarget.classList &&
      e.relatedTarget.classList.contains('toolbox_action')
    ) {
      return;
    }

    if (grid[rowIndex][colIndex].ref.current) {
      // Save current input in localStorage
      localStorage.setItem(
        'caretPos',
        grid[rowIndex][colIndex].ref.current.selectionStart,
      );
    }
    if (!pickMode.picking || force) {
      // Blur click of field (excluding pickmode)
      const newGrid = cloneDeep(grid);
      const { ref } = newGrid[rowIndex][colIndex];
      let text = pickMode.picking
        ? getPickedCellsText()
        : newGrid[rowIndex][colIndex].text;
      if (text.startsWith('=')) {
        // Do arithmetic calculations for = if (excluding pickmode and mobile)
        if (!pickMode.controlPressed && !isMobile) {
          if (isOpenParentheses(text)) {
            const possibleValue = evaluateExpression(
              `${text})`,
              grid,
              rowIndex,
              colIndex,
            );
            if (possibleValue !== 'Error') {
              text += ')';
            }
          }
        }
        newGrid[rowIndex][colIndex] = {
          value: evaluateExpression(text, grid, rowIndex, colIndex),
          valid: true,
          formula: text,
          text,
          initialText: text,
          ref,
          editing: false,
        };
      } else {
        if (shouldPrependZero(text)) {
          text = `0${text}`;
        }
        newGrid[rowIndex][colIndex] = {
          value: formatValue(text),
          valid: isValid(text),
          formula: null,
          text,
          initialText: text,
          ref,
          editing: false,
        };
      }

      reevaluateExpressions(newGrid);
      setGrid(newGrid);

      if (!fake) {
        localStorage.setItem('lastInteraction', 'blur');
        setTimeout(() => {
          checkFocus();
        }, 100);
      }
    }

    if (pickMode.picking) {
      // Set operator bar to OK.
      updateOperatorsBar('OK');
    } else {
      // Set operator bar to =.
      updateOperatorsBar('=');
    }
  };

  const cancelPickMode = (withBlur = true) => {
    if (
      pickMode.picking ||
      pickMode.controlPressed ||
      pickMode.pickCell[0] > -1 ||
      pickMode.rangeCells[0] > -1
    ) {
      if (withBlur) {
        onBlur(pickMode.cell[0], pickMode.cell[1], true);
      }
      setPickMode(initialPickMode);
    }
  };

  const checkPickMode = (rowIndex, colIndex, key = '', value = '') => {
    const { ref } = grid[rowIndex][colIndex];
    let { text } = grid[rowIndex][colIndex];
    let position = ref.current.selectionStart;
    if (value.length === 0) {
      switch (key) {
        case 'ArrowUp':
          if (!pickMode.picking) {
            position = 0;
          }
          break;
        case 'ArrowDown':
          if (!pickMode.picking) {
            position = text.length;
          }
          break;
        case 'ArrowRight':
          if (!pickMode.picking) {
            position += 1;
          }
          break;
        case 'ArrowLeft':
          if (!pickMode.picking) {
            position -= 1;
          }
          break;

        default:
          position = text.length;
          break;
      }
    } else {
      text = value;
    }
    if (position > text.length) {
      position = text.length;
    } else if (position < 0) {
      position = 0;
    }
    localStorage.setItem('caretPos', position);

    const index = text.indexOf('=');
    if (index === 0 && text.lastIndexOf('=') === index) {
      const isOperator = isArithmeticOperator(text.charAt(position - 1));
      if (isOperator && text.charAt(position) === '') {
        setPickMode({
          picking: true,
          shiftPressed: false,
          controlPressed: false,
          cell: [rowIndex, colIndex],
          pickCell: [-1, -1],
          rangeCells: [-1, -1, -1, -1],
          text,
          position,
        });
      } else if (pickMode.picking) {
        cancelPickMode();
      }
    } else if (pickMode.picking) {
      cancelPickMode();
    }
  };

  const onChange = (text, ref, rowIndex, colIndex) => {
    const newGrid = cloneDeep(grid);
    const { initialText } = newGrid[rowIndex][colIndex];

    localStorage.setItem(
      'caretPos',
      grid[rowIndex][colIndex].ref.current.selectionStart,
    );

    newGrid[rowIndex][colIndex] = {
      value: '',
      formula: null,
      text,
      initialText,
      ref,
      editing: true,
      valid: true,
    };

    if (text.length === 0) {
      cancelPickMode();
    } else {
      checkPickMode(rowIndex, colIndex, '', text);
    }

    if (operatorsBarRowIndex !== rowIndex) {
      setOperatorsBarRowIndex(rowIndex);
    }

    setGrid(newGrid);

    if (text.length === 0) {
      // Set operator bar to =.
      updateOperatorsBar('=');
    } else {
      // Set operator bar to OK.
      updateOperatorsBar('OK');
    }
  };

  const onTitleChange = (text) => {
    setSheetTitle(text);
  };

  const undoEdit = (rowIndex, colIndex) => {
    cancelPickMode();
    const newGrid = cloneDeep(grid);
    const { initialText, ref } = newGrid[rowIndex][colIndex];

    if (initialText.startsWith('=')) {
      newGrid[rowIndex][colIndex] = {
        value: evaluateExpression(initialText, grid, rowIndex, colIndex),
        formula: initialText,
        text: initialText,
        initialText,
        ref,
        editing: false,
      };
    } else {
      newGrid[rowIndex][colIndex] = {
        value: initialText,
        formula: null,
        text: initialText,
        initialText,
        ref,
        editing: false,
        valid: isValid(initialText),
      };
    }

    setGrid(newGrid);
  };

  const setEditing = (rowIndex, colIndex, editing, clear = false) => {
    const newGrid = cloneDeep(grid);
    const { ref, editing: alreadyEditing } = newGrid[rowIndex][colIndex];
    let { text } = newGrid[rowIndex][colIndex];
    const initialText = text;

    if (clear) {
      text = '';
      // Set operator bar to =.
      updateOperatorsBar('=');
    }

    // Set operator bar value.
    if (!text) {
      updateOperatorsBar('=');
    } else {
      updateOperatorsBar('OK');
    }

    if (!alreadyEditing && editing) {
      checkPickMode(rowIndex, colIndex);
    } else {
      cancelPickMode();
    }

    if (text.startsWith('=')) {
      newGrid[rowIndex][colIndex] = {
        value: evaluateExpression(text, grid, rowIndex, colIndex),
        formula: text,
        text,
        initialText,
        ref,
        editing,
        valid: true,
      };
    } else {
      newGrid[rowIndex][colIndex] = {
        value: text,
        formula: null,
        text,
        initialText,
        ref,
        editing,
        valid: true,
      };
    }

    if (editing && operatorsBarRowIndex !== rowIndex) {
      setOperatorsBarRowIndex(rowIndex);
    }

    setGrid(newGrid);
  };

  const onFocusMobile = (rowIndex, colIndex) => {
    const newGrid = cloneDeep(grid);
    if (activeCell[0] > -1) {
      const cell = newGrid[activeCell[0]][activeCell[1]];
      cell.editing = false;

      const append = localStorage.getItem('append');
      if (append && append.length > 0) {
        cell.text = append;
        localStorage.setItem('append', '');
      }
      if (!cell.text.startsWith('=')) {
        cell.value = formatValue(cell.text);
        cell.valid = isValid(cell.text);
      }

      const mycell = newGrid[rowIndex][colIndex];
      const index = mycell.text.indexOf('=');
      if (index === 0 && mycell.text.lastIndexOf('=') === index) {
        const isOperator = isArithmeticOperator(
          mycell.text.charAt(mycell.text.length - 1),
        );
        if (isOperator) {
          setPickMode({
            picking: true,
            shiftPressed: false,
            controlPressed: false,
            cell: [rowIndex, colIndex],
            pickCell: [-1, -1],
            rangeCells: [-1, -1, -1, -1],
            text: mycell.text,
            position: mycell.text.length,
          });
        }
      }

      reevaluateExpressions(newGrid);

      if (mycell.text.length === 0) {
        // Set operator bar to =.
        updateOperatorsBar('=');
      } else {
        // Set operator bar to OK.
        updateOperatorsBar('OK');
      }
    }
    newGrid[rowIndex][colIndex].editing = true;

    if (operatorsBarRowIndex !== rowIndex) {
      setOperatorsBarRowIndex(rowIndex);
    }

    setGrid(newGrid);
  };

  const onFocus = (e, rowIndex, colIndex, force = false) => {
    setDisplayedCommentIndex(-1);
    if (
      !pickMode.picking ||
      force ||
      (localStorage.getItem('append') &&
        localStorage.getItem('append').length > 0)
    ) {
      localStorage.setItem('lastInteraction', 'focus');
      setPreviousActiveCell(activeCell);
      setActiveCell([rowIndex, colIndex]);
      if (isMobile) {
        onFocusMobile(rowIndex, colIndex);
      }
    }

    if (pickMode.picking || force) {
      // Set operator bar to OK.
      updateOperatorsBar('OK');
    } else if (
      e &&
      e.relatedTarget &&
      e.relatedTarget.classList &&
      !e.relatedTarget.classList.contains('toolbox_action')
    ) {
      // Set operator bar to = if not from toolbox action.
      updateOperatorsBar('=');
    }
  };

  // Reset grid after toolbox changes on Desktop
  const gridSetDesktop = (rowIndex, colIndex) => {
    const newGrid = cloneDeep(grid);
    if (activeCell[0] > -1) {
      const cell = newGrid[activeCell[0]][activeCell[1]];
      cell.editing = false;

      const append = localStorage.getItem('append');
      if (append && append.length > 0) {
        cell.text = append;
        localStorage.setItem('append', '');
      }
      if (!cell.text.startsWith('=')) {
        cell.value = formatValue(cell.text);
        cell.valid = isValid(cell.text);
      }

      const mycell = newGrid[rowIndex][colIndex];
      const index = mycell.text.indexOf('=');
      if (index === 0 && mycell.text.lastIndexOf('=') === index) {
        const isOperator = isArithmeticOperator(
          mycell.text.charAt(mycell.text.length - 1),
        );
        if (isOperator) {
          setPickMode({
            picking: true,
            shiftPressed: false,
            controlPressed: false,
            cell: [rowIndex, colIndex],
            pickCell: [-1, -1],
            rangeCells: [-1, -1, -1, -1],
            text: mycell.text,
            position: mycell.text.length,
          });
        }
      }

      reevaluateExpressions(newGrid);
    }
    newGrid[rowIndex][colIndex].editing = true;

    if (operatorsBarRowIndex !== rowIndex) {
      setOperatorsBarRowIndex(rowIndex);
    }

    setGrid(newGrid);
  };

  const changeCell = (vertical, horizontal) => {
    const newRowIndex = activeCell[0] + vertical;
    if (newRowIndex > 9 || newRowIndex < 0) {
      return false;
    }
    const newColIndex = activeCell[1] + horizontal;
    if (newColIndex > 1 || newColIndex < 0) {
      return false;
    }
    const { ref } = grid[newRowIndex][newColIndex];
    ref.current.focus();
    cancelPickMode();
    return true;
  };

  const handleKeyPress = (e, rowIndex, colIndex, editing) => {
    if (editing) {
      // Only handle 'Enter' key press if Operator Button is enabled.
      if (e.key === 'Enter') {
        cancelPickMode();
        if (!pickMode.picking) {
          const didChangeCell = changeCell(1, 0);
          if (!didChangeCell) {
            onBlur(rowIndex, colIndex);
          }
        } else {
          // Set focus to next cell.
          changeCell(rowIndex, colIndex);
        }
      }
    } else if (e.key === 'Enter') {
      setEditing(rowIndex, colIndex, true);
    } else if (pickMode.picking) {
      e.preventDefault();
    } else {
      setEditing(rowIndex, colIndex, true, true);
    }
  };

  const appendTextMobile = (text) => {
    let value = text;
    const cell = grid[activeCell[0]][activeCell[1]];
    if (cell.text.length === 0 && value.length > 1) {
      value = `=${value}`;
    }
    if (value === '=') {
      if (cell.text.length > 0) {
        if (!isOpenParentheses(cell.text)) {
          handleKeyPress({ key: 'Enter' }, activeCell[0], activeCell[1], true);
          return;
        }
        value = ')';
      }
    }
    const el = cell.ref.current;
    if (pickMode.picking || cell.text.length === 0) {
      el.value += value;
      localStorage.setItem('append', el.value);
    } else {
      const caretPos = localStorage.getItem('caretPos')
        ? localStorage.getItem('caretPos')
        : 0;
      const firstPart = cell.text.substr(0, caretPos);
      const secondPart = cell.text.substr(caretPos);
      const newValue = firstPart + value + secondPart;
      localStorage.setItem('append', newValue);
      localStorage.setItem('caretPos', firstPart.length + text.length);
    }
    el.focus();
  };

  const appendText = (text) => {
    let value = text;
    const cell = grid[activeCell[0]][activeCell[1]];

    // Should replace cell value with =.
    let shouldReplaceVals = false;
    let shouldReplaceFunctions = false;
    let arithmeticOrCurrency = false;

    // Change operator value if arithmetic value.
    if (isArithmeticOperator(value) || value === '$' || value === '%') {
      updateOperatorsBar('OK');
      arithmeticOrCurrency = true;
    }

    // replace cell with =.
    if (
      value === '=' &&
      equalButtonValue === '=' &&
      !isOpenParentheses(value)
    ) {
      cell.text = '=';
      shouldReplaceVals = true;
    }

    // Apply = logic on mobile.
    if (shouldReplaceVals && cell.text.length === 0 && value.length > 1) {
      value = `=${value}`;
    }

    // Handle functions.
    if (isOpenParentheses(value)) {
      cell.text = `=${value}`;
      shouldReplaceFunctions = true;
    }

    if (value === '=') {
      if (
        cell.text.length > 0 &&
        (!shouldReplaceVals || shouldReplaceFunctions)
      ) {
        if (!isOpenParentheses(cell.text)) {
          // Trigger manual setEdit for toolbox desktop
          setEditing(activeCell[0], activeCell[1], false);

          handleKeyPress({ key: 'Enter' }, activeCell[0], activeCell[1], true);

          // Focus bug fix.
          if (pickMode.picking && equalButtonValue === 'OK') {
            grid[activeCell[0]][activeCell[1]].ref.current.focus();
          }

          if (equalButtonValue === 'OK') {
            // Force operator bar update.
            updateOperatorsBar('=');

            // Trigger force blur.
            onBlur(activeCell[0], activeCell[1], true);
          }

          return;
        }
        value = ')';
      }
    }
    const el = cell.ref.current;
    if (pickMode.picking || cell.text.length === 0) {
      el.value += value;
      localStorage.setItem('append', el.value);

      // Auto-handle functions.
      if (isOpenParentheses(cell.text) && equalButtonValue === 'OK') {
        // if function does not includes multiple picks.
        // Force keypress.
        handleKeyPress({ key: 'Enter' }, activeCell[0], activeCell[1], true);
      }
    } else {
      const caretPos = localStorage.getItem('caretPos')
        ? localStorage.getItem('caretPos')
        : 0;
      const firstPart = cell.text.substr(0, caretPos);
      const secondPart = cell.text.substr(caretPos);
      let newValue = firstPart + value + secondPart;

      // Set values order for arithmetic and currency values.
      if (arithmeticOrCurrency) {
        newValue = firstPart + secondPart + value;
      }

      // Reset value if = or function is being handled.
      if (shouldReplaceVals || shouldReplaceFunctions) {
        newValue = '';
      }

      localStorage.setItem('append', newValue);
      localStorage.setItem('caretPos', firstPart.length + text.length);
    }
    el.focus();

    // Reset grid on desktop
    gridSetDesktop(activeCell[0], activeCell[1]);
  };

  const addPickCells = (rowIndex, colIndex, shiftPressed = false) => {
    if (pickMode.picking) {
      const newPickMode = cloneDeep(pickMode);
      newPickMode.pickCell = [rowIndex, colIndex];
      if (pickMode.shiftPressed || shiftPressed) {
        if (newPickMode.rangeCells[0] === -1) {
          newPickMode.rangeCells = [
            pickMode.pickCell[0],
            pickMode.pickCell[1],
            pickMode.pickCell[0],
            pickMode.pickCell[1],
          ];
        } else {
          newPickMode.rangeCells[2] = rowIndex;
          newPickMode.rangeCells[3] = colIndex;
        }
      } else {
        newPickMode.rangeCells = [-1, -1, -1, -1];
      }
      if (shiftPressed) {
        newPickMode.shiftPressed = true;
      }
      setPickMode(newPickMode);
    }
  };

  const onClick = (rowIndex, colIndex) => {
    const { editing } = grid[rowIndex][colIndex];
    if (pickMode.controlPressed) {
      const firstletter = colIndex === 0 ? 'A' : 'B';
      const firstCell = `${firstletter}${rowIndex + 1}`;
      grid[pickMode.cell[0]][pickMode.cell[1]].ref.current.focus();
      const newGrid = cloneDeep(grid);
      newGrid[pickMode.cell[0]][pickMode.cell[1]].editing = true;
      const { text } = newGrid[pickMode.cell[0]][pickMode.cell[1]];
      if (text.startsWith('=') && text.length > 1 && isOpenParentheses(text)) {
        if (
          text.charAt(text.length - 1) !== ',' &&
          text.charAt(text.length - 1) !== '('
        ) {
          newGrid[pickMode.cell[0]][pickMode.cell[1]].text += ',';
        }
        newGrid[pickMode.cell[0]][pickMode.cell[1]].text += firstCell;
      }
      setGrid(newGrid);
    } else if (
      previousActiveCell[0] !== activeCell[0] ||
      previousActiveCell[1] !== activeCell[1]
    ) {
      // Check if a different cell was clicked
      setPreviousActiveCell(activeCell);
    } else if (pickMode.picking) {
      // Check if picking cells for calculations
      addPickCells(rowIndex, colIndex);
      grid[pickMode.cell[0]][pickMode.cell[1]].ref.current.focus();
    } else if (!editing && !pickMode.picking) {
      // Edit mode in cell
      setEditing(rowIndex, colIndex, true);
    }
  };

  const changePickCell = (key) => {
    let vertical = 0;
    let horizontal = 0;
    switch (key) {
      case 'ArrowUp':
        vertical = -1;
        break;
      case 'ArrowDown':
        vertical = 1;
        break;
      case 'ArrowRight':
        horizontal = 1;
        break;
      case 'ArrowLeft':
        horizontal = -1;
        break;

      default:
        break;
    }

    const v =
      pickMode.pickCell[0] === -1 ? pickMode.cell[0] : pickMode.pickCell[0];
    const h =
      pickMode.pickCell[1] === -1 ? pickMode.cell[1] : pickMode.pickCell[1];
    let newRowIndex = v + vertical;
    if (newRowIndex > 9) {
      newRowIndex = 9;
    }
    if (newRowIndex < 0) {
      newRowIndex = 0;
    }
    let newColIndex = h + horizontal;
    if (newColIndex > 1) {
      newColIndex = 1;
    }
    if (newColIndex < 0) {
      newColIndex = 0;
    }

    addPickCells(newRowIndex, newColIndex);
  };

  const handleKeyUp = (e, rowIndex, colIndex, editing) => {
    if (
      e.key === 'Shift' &&
      editing &&
      pickMode.picking &&
      pickMode.shiftPressed
    ) {
      const newPickMode = cloneDeep(pickMode);
      newPickMode.shiftPressed = false;
      setPickMode(newPickMode);
    }
    if (
      editing &&
      (e.key === 'Control' || e.key === 'Alt' || e.key === 'Meta')
    ) {
      const newPickMode = cloneDeep(pickMode);
      newPickMode.controlPressed = false;
      setPickMode(newPickMode);
      checkPickMode(rowIndex, colIndex);
    }
  };

  const onMouseMove = (rowIndex, colIndex) => {
    if (pickMode.picking && isMouseDown) {
      addPickCells(rowIndex, colIndex);
    }
  };

  const onMouseDown = (rowIndex, colIndex) => {
    if (
      pickMode.picking &&
      !pickMode.shiftPressed &&
      !pickMode.controlPressed
    ) {
      const newPickMode = cloneDeep(pickMode);
      newPickMode.shiftPressed = true;
      newPickMode.pickCell = [rowIndex, colIndex];
      newPickMode.rangeCells = [rowIndex, colIndex, rowIndex, colIndex];
      setPickMode(newPickMode);
    }
  };

  const containerMouseUp = () => {
    setIsMouseDown(false);
    if (pickMode.picking && pickMode.shiftPressed) {
      if (isRangeCells(pickMode.rangeCells)) {
        const newPickMode = cloneDeep(pickMode);
        newPickMode.shiftPressed = false;
        setPickMode(newPickMode);
        grid[pickMode.cell[0]][pickMode.cell[1]].ref.current.focus();
      } else {
        const newPickMode = cloneDeep(pickMode);
        newPickMode.shiftPressed = false;
        setPickMode(newPickMode);
      }
    }
  };

  const containerMouseDown = () => {
    setIsMouseDown(true);
  };

  const onMouseUp = (rowIndex, colIndex) => {
    if (operatorsBarRowIndex === rowIndex - 1) {
      return;
    }
    if (!pickMode.picking && isMobile) {
      onChange(
        grid[rowIndex][colIndex].text,
        grid[rowIndex][colIndex].ref,
        rowIndex,
        colIndex,
      );
    }
  };

  const handleKeyDown = (e, rowIndex, colIndex, editing) => {
    if (editing) {
      switch (e.key) {
        case 'Escape':
          cancelPickMode();
          undoEdit(rowIndex, colIndex);
          break;
        case 'ArrowUp':
        case 'ArrowDown':
        case 'ArrowRight':
        case 'ArrowLeft':
          checkPickMode(rowIndex, colIndex, e.key);
          if (pickMode.picking) {
            e.preventDefault();
            changePickCell(e.key);
          }
          break;
        case 'Shift':
          if (pickMode.picking) {
            addPickCells(pickMode.pickCell[0], pickMode.pickCell[1], true);
          }
          break;
        case 'Alt':
        case 'Control':
        case 'Meta': {
          const newPickMode = cloneDeep(pickMode);
          newPickMode.controlPressed = true;
          newPickMode.shiftPressed = false;
          newPickMode.picking = false;
          newPickMode.cell = [rowIndex, colIndex];
          newPickMode.pickCell = [-1, -1];
          newPickMode.rangeCells = [-1, -1, -1, -1];
          setPickMode(newPickMode);
          break;
        }

        default:
          break;
      }
    } else {
      switch (e.key) {
        case 'ArrowDown':
          changeCell(1, 0);
          break;
        case 'ArrowUp':
          changeCell(-1, 0);
          break;
        case 'ArrowRight':
          changeCell(0, 1);
          break;
        case 'ArrowLeft':
          changeCell(0, -1);
          break;
        case 'Backspace':
        case 'Delete':
          setEditing(rowIndex, colIndex, false, true);
          break;

        default:
          break;
      }
    }
  };

  const renderCopyLink = () => (
    <CopyToClipboard
      text={window.location}
      onCopy={() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }}
    >
      <button className="dropdown-item button icon-link" type="button">
        <CopyIcon className="button-icon" />
        <span className="button-text">Copy link</span>
      </button>
    </CopyToClipboard>
  );

  const renderEmbedFooter = () => (
    <div className="embed-footer">
      <a
        className="logo-group centered"
        href={generateCurrentSheetURL(false)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Logo className="logo-footer" />
        <Wordmark className="wordmark-footer" />
      </a>
      <span className="sheet-footer-link">
        A Labs project from{' '}
        <a
          href={POSTLIGHT_URL}
          onClick={sendGtagClickEventPLDotCom}
          className="link"
        >
          Postlight
        </a>
      </span>
    </div>
  );

  const toggleEmbedModal = () => {
    setShowEmbedModal(!showEmbedModal);
  };

  const renderFooter = () => (
    <div className="buttons-container">
      {copied ? (
        <span className="button-text fade-out">Link copied</span>
      ) : null}
      {isSaved && !copied ? (
        <div className="icon-link">
          <SavingIcon className="button-icon" />
          <span className="button-text">Updated</span>
        </div>
      ) : (
        <div />
      )}
      {showCopy && (
        <div className="dropdown">
          <input
            value={showShareMenu}
            readOnly
            onClick={() => setShowShareMenu(!showShareMenu)}
            onBlur={() => setShowShareMenu(false)}
            className="hidden-dropdown-input"
          />
          <button className="button icon-link" type="button">
            <ShareIcon className="button-icon" />
            <span className="button-text">Share</span>
          </button>
          <div className={`dropdown-content ${showShareMenu ? 'show' : ''}`}>
            {renderCopyLink()}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="dropdown-item icon-link"
              href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                `${shareTitle(sheetTitle)}`,
              )}&url=${encodeURIComponent(window.location.href)}`}
            >
              <TwitterIcon className="button-icon" />
              <span className="button-text">Tweet</span>
            </a>
            <button
              className="dropdown-item button icon-link"
              type="button"
              onClick={toggleEmbedModal}
            >
              <EmbedIcon className="button-icon" />
              <span className="button-text">Embed</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );

  const resetSheet = () => {
    window.location.hash = initialHash;
    resetButton.current.blur();
    setInit(false);
  };

  const saveComment = (comment, rowIndex, deleteComment = false) => {
    let newComments = cloneDeep(comments);
    // Previous version compatibility fix.
    if (!newComments) {
      newComments = createEmptyComments(10, 2);
    }
    newComments[rowIndex] = comment;
    setComments(newComments);
    if (deleteComment) {
      setDisplayedCommentIndex(-1);
    }
  };

  const pickRow = pickMode.pickCell ? pickMode.pickCell[0] : -1;
  const pickCol = pickMode.pickCell ? pickMode.pickCell[1] : -1;
  const aBackground = activeCell[1] === 0 ? rowAndColIndicatorColor : '';
  const bBackground = activeCell[1] === 1 ? rowAndColIndicatorColor : '';

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className="container"
      onMouseUp={() => containerMouseUp()}
      onMouseDown={() => containerMouseDown()}
    >
      <div className="sheet-title-container">
        {isEmbed ? (
          <div className="sheet-title" title={sheetTitle}>
            {sheetTitle}
          </div>
        ) : (
          <input
            maxLength="200"
            className="sheet-title"
            value={sheetTitle}
            placeholder="Untitled Sheet"
            onChange={(e) => onTitleChange(e.target.value)}
          />
        )}

        {isEmbed && (
          <button
            onClick={resetSheet}
            className="button no-outline hasHoverTitle"
            data-hovertitle="Reset"
            type="button"
            ref={resetButton}
          >
            <RefreshIcon />
          </button>
        )}
      </div>
      <div className="sheet-container">
        <table>
          <tbody>
            <tr>
              <td
                className="col-label"
                style={{
                  borderLeft: '0',
                  background: emptyTopLeftCellColor,
                  borderRadius: '5px 0px 0px 0px',
                }}
              />
              <td className="col-label" style={{ background: aBackground }}>
                A
              </td>
              <td
                className="col-label"
                style={{
                  background: bBackground,
                  borderRadius: '0px 5px 0px 0px',
                }}
              >
                B
              </td>
            </tr>
            {grid.map((row, rowIndex) => {
              const rowBackground =
                activeCell[0] === rowIndex ? rowAndColIndicatorColor : '';
              return (
                <React.Fragment key={`row_${rowIndex}`}>
                  <tr key={`row_${rowIndex}`}>
                    <td
                      className="row-number"
                      style={{
                        background: rowBackground,
                      }}
                    >
                      {rowIndex + 1}
                      <Comment
                        showComment={() => setDisplayedCommentIndex(rowIndex)}
                        cancelComment={() => setDisplayedCommentIndex(-1)}
                        saveComment={saveComment}
                        rowIndex={rowIndex}
                        show={displayedCommentIndex === rowIndex}
                        isRowActive={activeCell[0] === rowIndex}
                        comment={comments && comments[rowIndex]}
                        isEmbed={isEmbed}
                      />
                    </td>
                    {row.map(
                      ({ text, value, ref, editing, valid }, colIndex) => {
                        const isActiveCell =
                          activeCell[0] === rowIndex &&
                          activeCell[1] === colIndex;
                        let cellStyle =
                          colIndex === 0 ? 'label-td' : 'value-td';
                        let inputGeneralStyle =
                          colIndex === 0 ? 'label-input' : 'value-input';
                        const spellCheck = colIndex === 0;
                        let iStyle = editing ? editStyle : inputStyle;
                        if (!editing && valid) {
                          iStyle = rightStyle;
                        }
                        let tdStyle = isActiveCell ? activeStyle : {};
                        iStyle = cloneDeep(iStyle);
                        if (isActiveCell && colIndex === 1 && !editing) {
                          iStyle.background = 'white';
                        }
                        let finalValue = text;
                        if (!editing) {
                          finalValue = value === '' ? text : value;
                        } else if (pickMode.controlPressed) {
                          finalValue = text;
                        } else if (pickMode.picking) {
                          finalValue = getPickedCellsText(rowIndex, colIndex);
                          if (isMobile) {
                            if (`${finalValue})` === text) {
                              finalValue = text;
                              setPickMode(initialPickMode);
                            }
                          }
                        }
                        const type = colIndex === 1 ? 'decimal' : 'text';
                        const inputmode = colIndex === 1 ? 'decimal' : 'text';
                        const pattern = colIndex === 1 ? '[0-9]*' : '';

                        if (finalValue === 'Error' && valid && !editing) {
                          cellStyle += ' error-td';
                          inputGeneralStyle += ' error';
                        }

                        if (
                          isInInterval(rowIndex, colIndex, pickMode.rangeCells)
                        ) {
                          tdStyle = pickStyle;
                          iStyle.background = selectedCellColor;
                        } else if (
                          pickRow === rowIndex &&
                          pickCol === colIndex
                        ) {
                          tdStyle = pickStyle;
                          iStyle.background = selectedCellColor;
                        } else if (
                          pickMode.picking &&
                          pickMode.cell[0] === rowIndex &&
                          pickMode.cell[1] === colIndex
                        ) {
                          tdStyle = readyToPickStyle;
                        }

                        return (
                          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                          <td
                            key={`cell_${colIndex}${rowIndex}`}
                            className={cellStyle}
                            style={tdStyle}
                            onMouseMove={() => onMouseMove(rowIndex, colIndex)}
                            onMouseDown={() => onMouseDown(rowIndex, colIndex)}
                            onMouseUp={() => onMouseUp(rowIndex, colIndex)}
                          >
                            <input
                              maxLength="100"
                              style={iStyle}
                              ref={ref}
                              className={inputGeneralStyle}
                              type={type}
                              pattern={pattern}
                              inputMode={inputmode}
                              value={finalValue}
                              spellCheck={spellCheck}
                              onChange={(e) => {
                                showSaveIndicator();
                                onChange(
                                  e.target.value,
                                  ref,
                                  rowIndex,
                                  colIndex,
                                );
                              }}
                              onClick={() => onClick(rowIndex, colIndex)}
                              onBlur={(e) =>
                                onBlur(rowIndex, colIndex, false, false, e)
                              }
                              onFocus={(e) => onFocus(e, rowIndex, colIndex)}
                              onKeyPress={(e) =>
                                handleKeyPress(e, rowIndex, colIndex, editing)
                              }
                              onKeyDown={(e) =>
                                handleKeyDown(e, rowIndex, colIndex, editing)
                              }
                              onKeyUp={(e) =>
                                handleKeyUp(e, rowIndex, colIndex, editing)
                              }
                            />
                          </td>
                        );
                      },
                    )}
                  </tr>
                  {isMobile && rowIndex === operatorsBarRowIndex && (
                    <Toolbox
                      appendText={appendTextMobile}
                      equalButtonValue={equalButtonValue}
                    />
                  )}
                </React.Fragment>
              );
            })}
            <tr>
              <td colSpan="3" className="sheet-footer">
                {isEmbed ? renderEmbedFooter() : renderFooter()}
              </td>
            </tr>
          </tbody>
        </table>
        {!isMobile && !isEmbed && (
          <table className="toolbox-container">
            <tbody>
              <Toolbox
                appendText={appendText}
                equalButtonValue={equalButtonValue}
                verticalToolBox
              />
            </tbody>
          </table>
        )}
      </div>
      <EmbedModal show={showEmbedModal} hideModal={toggleEmbedModal} />
    </div>
  );
};

export default Sheet;
