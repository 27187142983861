import React from 'react';

import { ReactComponent as PostlightLogo } from '../../images/postlight.svg';

import { POSTLIGHT_URL } from '../../constants';
import { sendGtagClickEventPLDotCom } from '../../helpers';

const Footer = () => (
  <footer className="footer">
    <span className="footer-labs-text">A Labs project from</span>
    <a
    href={POSTLIGHT_URL}
    onClick={sendGtagClickEventPLDotCom}
    className="footer-link">
      <PostlightLogo className="footer-logo" height="25" width="97" />
    </a>
  </footer>
);

export default Footer;
