import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { ReactComponent as AddCommentIcon } from '../../images/add-comment.svg';
import { ReactComponent as CommentIcon } from '../../images/comment.svg';

const MAX_CHARS = 130;

const Comment = ({
  show,
  showComment,
  cancelComment,
  saveComment,
  rowIndex,
  comment: initialComment,
  isRowActive,
  isEmbed,
}) => {
  // Comment component node
  const commentNode = useRef();
  const commentBtnNode = useRef();

  const [comment, setComment] = useState('');

  // Editing comment
  const [editingComment, setEditingComment] = useState(false);
  const commentFieldInput = useRef(null);

  // Comment Height.
  const [commentHeight, setCommentHeight] = useState(0);
  const commentEl = useRef();

  useLayoutEffect(() => {
    if (commentEl.current) {
      setCommentHeight(commentEl.current.clientHeight);
    }
  }, [editingComment]);

  // Handle outside click
  const handleClickOutside = (e) => {
    if (
      commentNode.current.contains(e.target) ||
      commentBtnNode.current.contains(e.target)
    ) {
      // inside click
      return;
    }
    // outside click
    cancelComment();
  };

  // Editing comments useEffect hook
  useEffect(() => {
    if (!editingComment) commentFieldInput.current.focus();
  }, [editingComment]);

  useEffect(() => {
    setComment(initialComment);
  }, [initialComment]);

  useEffect(() => {
    if (show && !editingComment) commentFieldInput.current.focus();

    // Handle outside clicks
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      setComment(initialComment);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [show]);

  const saveTemporaryComment = (event) => {
    setComment(event.target.value);
  };

  const addNewComment = () => {
    // Toggle hiding/showing comment box
    if (show) {
      cancelComment();
    } else {
      showComment();
    }
  };

  const editCommentBox = () => {
    // Toggle hiding/showing comment box
    if (show) {
      cancelComment();
    } else {
      showComment();
      setEditingComment(true);
    }
  };

  const editExistingBox = () => {
    setEditingComment(false);
  };

  const deleteComment = () => {
    saveComment('', rowIndex, true);
  };

  const doSaveComment = (retComment, retRow) => {
    if (retComment) {
      // Save comment
      saveComment(retComment, retRow);

      // Switch to edit view
      setEditingComment(true);
    }
  };

  const addCommentButton = (
    <>
      <button
        type="button"
        ref={commentBtnNode}
        className="button comment-icon-container"
        onClick={addNewComment}
      >
        <AddCommentIcon className="comment-icon" />
      </button>
    </>
  );

  const editCommentButton = (
    <>
      <button
        type="button"
        ref={commentBtnNode}
        className="button comment-icon-container"
        onClick={editCommentBox}
      >
        <CommentIcon className="comment-icon" />
      </button>
    </>
  );

  const reduceFromComments = isEmbed ? 12 : 28;
  return (
    <>
      {initialComment // eslint-disable-line no-nested-ternary
        ? editCommentButton
        : isRowActive
        ? addCommentButton
        : null}
      <div
        ref={commentNode}
        className={`comment-content ${rowIndex === 0 ? 'first_comment' : ''} ${
          show ? 'show' : ''
        }`}
        style={
          rowIndex === 0 && editingComment
            ? {
                bottom: -commentHeight - reduceFromComments,
              }
            : {}
        }
      >
        {!editingComment ? (
          <div className="comment-box-container">
            <div className="comment-field-container">
              <textarea
                className="comment-box"
                ref={commentFieldInput}
                onChange={saveTemporaryComment}
                value={comment}
                placeholder="Add a comment for this row…"
                maxLength={MAX_CHARS}
                onFocus={(e) => {
                  const val = e.target.value;
                  e.target.value = '';
                  e.target.value = val;
                }}
              />
              <span className="comment-box-remaining-chars">
                {comment ? comment.length : 0}/ {MAX_CHARS}
              </span>
            </div>
          </div>
        ) : (
          <div
            className="comment-box-container comment-edit"
            style={{
              height: commentHeight,
            }}
          >
            <div className="comment-box-holder">
              <p className="comment-box" ref={commentEl}>
                {comment}
              </p>
            </div>
          </div>
        )}
        {!editingComment && (
          <div className="comment-actions">
            {initialComment ? (
              <button
                type="button"
                className="button delete-button secondary"
                onClick={deleteComment}
              >
                Delete
              </button>
            ) : (
              ''
            )}
            <button
              type="button"
              className="button secondary"
              onClick={cancelComment}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`button primary ${!comment ? 'disabled_btn' : ''}`}
              onClick={() => doSaveComment(comment, rowIndex)}
            >
              Save
            </button>
          </div>
        )}
        {!isEmbed && editingComment && (
          <div className="comment-actions comment-action-edit">
            <button
              type="button"
              className="button edit-button primary"
              onClick={editExistingBox}
            >
              Edit
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default Comment;
