import React from 'react';

// Custom scroll bar used in Desktop ToolBox
import { Scrollbars } from 'react-custom-scrollbars';

type Props = {
  appendText: (value: string) => void,
  equalButtonValue: String,
  verticalToolBox: Boolean,
};

const Toolbox = ({ appendText, equalButtonValue, verticalToolBox }: Props) => {
  const chars = ['+', '-', '*', '/', '%', '$', ',', ':'];
  return (
    <tr>
      {verticalToolBox ? (
        <th colSpan="3" className="operators vertical">
          <div className="operators-content">
            <Scrollbars style={{ width: 53, height: 355 }}>
              <div className="scroll-content">
                {chars.map(char => (
                  <button
                    key={char}
                    type="button"
                    className="toolbox_action"
                    onClick={() => appendText(char)}
                  >
                    {char}
                  </button>
                ))}
                <button
                  type="button"
                  className="func-button toolbox_action"
                  onClick={() => appendText('SUM(')}
                >
                  SUM
                  <span className="parenthesis">()</span>
                </button>
                <button
                  type="button"
                  className="func-button toolbox_action"
                  onClick={() => appendText('AVG(')}
                >
                  AVG
                  <span className="parenthesis">()</span>
                </button>
              </div>
            </Scrollbars>
          </div>
          <button
            type="button"
            className="equal-button toolbox_action"
            onClick={() => appendText('=')}
          >
            {equalButtonValue}
          </button>
        </th>
      ) : (
        <th colSpan="3" className="operators">
          <div className="operators-content">
            {chars.map(char => (
              <button
                key={char}
                type="button"
                onClick={() => appendText(char)}
              >
                {char}
              </button>
            ))}
            <button
              type="button"
              className="func-button"
              onClick={() => appendText('SUM(')}
            >
              SUM
              <span className="parenthesis">()</span>
            </button>
            <button
              type="button"
              className="func-button"
              onClick={() => appendText('AVG(')}
            >
              AVG
              <span className="parenthesis">()</span>
            </button>
          </div>
          <button
            type="button"
            className="equal-button"
            onClick={() => appendText('=')}
          >
            {equalButtonValue}
          </button>
        </th>
      )}
    </tr>
  );
};

export default Toolbox;
