/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */

import React from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import Sheet from './components/Sheet';

import './App.css';
import './EmbeddedApp.css';

const App = ({ pathArr }) => {
  const isEmbed = pathArr[0] === 'embed';
  return (
    <div className={`root-content ${isEmbed ? 'is-embed' : 'has-background'}`}>
      {isEmbed ? (
        <Sheet isEmbed={isEmbed} />
      ) : (
        <>
          <Header />
          <Sheet isEmbed={isEmbed} />
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
