import React from 'react';
import { isMobile } from 'react-device-detect';

import { ReactComponent as HelpIcon } from '../../images/help.svg';
import { ReactComponent as Wordmark } from '../../images/wordmark.svg';
import { ReactComponent as Logo } from '../../images/logo.svg';

const Header = () => (
  <header>
    <div className="header-content">
      <div className="logo-group">
        <Logo className="logo" />
        <Wordmark className="wordmark" />
      </div>
      <div className="menu-container">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://postlight.com/labs/tinysheet"
          title="Learn more about Tinysheet @ Postlight Labs"
        >
          <HelpIcon className="help-icon mobile" />
        </a>
        <a
          className="menu-item"
          target="_blank"
          rel="noopener noreferrer"
          href={window.location.href}
          onClick={(e) => {
            e.preventDefault();
            window.open(window.location.href, "_blank");
          }}
        >
          Duplicate
        </a>
        <a
          className="menu-item"
          target="_blank"
          rel="noopener noreferrer"
          href="/"
        >
          New{isMobile ? '' : ' sheet'}
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://postlight.com/labs/tinysheet"
          title="Learn more about Tinysheet @ Postlight Labs"
        >
          <HelpIcon className="help-icon desktop" />
        </a>
      </div>
    </div>
  </header>
);

export default Header;
